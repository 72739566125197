<template>
  <div class="searchfilter-page has-background-purple-5 is-flex is-flex-direction-column is-align-items-center">

    <Icon class="is-fixed is-right-0 is-top-0 is-size-4 has-text-dark m-5" :icon="['far', 'times-circle']" @click="$emit('close')"/>
    
    <div class="is-full-width">
      <h1 class="m-2 ml-4 has-text-weight-bold is-size-5 is-float-left">Location</h1>
    </div>
    

    <div v-if="possibleRootContainers" class="searchFilterBox mt-2 is-full-width">
      <h2 class="has-text-left m-2">Storage</h2>
      <div class="is-flex is-flex-direction-row is-flex-wrap-wrap">
          <div v-for="container in possibleRootContainers" :key="container.id">
            <Pill hideDeleteButton="true" 
              :class="{activeFilter:!tagNotInChosenLocations(container),'mt-1 ml-1 rootStorageTag':true}" 
              :tag="container.name" 
              @onTagClick="setLocationFilter($event, container)"
              />
          </div>
      </div>
    </div>

    <div class="searchFilterBox mt-2 is-full-width is-half-height is-flex is-flex-direction-column">
      <div class="has-text-left m-2 is-flex"><h2 class="">Location (optional)</h2><button class="button is-size-7 ml-2" @click="resetLocationFilters">clear</button></div>
        <LocationInput :point="mapCenter" @locationUpdate="handleLocationInputUpdate"/>
        <Map :area="mapArea" :marker="mapMarker" :zoom="12.5"
          @markerMoved="handleMapMarkerMoved" 
          @centerMoved="handleMapCenterMoved" 
          @mapClicked="activateMarker"
          class="is-full-height"
        />
        <div class="is-flex is-align-items-center is-full-width">
              <div>radius </div> 
              <input 
                @input="calculateUserChoosenArea"
                ref="radius" 
                class="input is-half-width m-1" 
                placeholder="radius in meters" type=number
                :value="$store.state.data.searchResultFilterRadius || 100"
              />  
        </div>
    </div>
  

  </div>
</template>

<script>

// TODO: get potential searchAreaFilter from store on mount and use that over all other if present

import Pill from '@/components/Template/Pill';
import Map from '@/components/Location/Map';
import LocationInput from '@/components/Location/LocationInput';

import { useStore } from 'vuex';
import { isEqual } from "lodash";
import { ref } from '@vue/reactivity';

import circle from '@turf/circle'
import center from '@turf/center'
import { onMounted } from '@vue/runtime-core';

export default {
  name: 'SearchFilterLocation',
  components:{
    Pill,
    Map,
    LocationInput
  },
  props: [
    'possibleRootContainers',
    'chosenLocations'
  ],
  setup(props){
    console.log("SETUP");

    const store = useStore();

    const mapMarker = ref(null); // {coords:[48.904612356,9.19594345]}
    const mapArea = ref(store.state.data.searchResultFilterGeolocation || null);
    const mapCenter = ref(null)
    const radius = ref(null);

    onMounted(()=>{
      if (mapArea.value !== null){
        activateMarker();
        console.log("searchArea set and should be visualised");
      }
      if (store.state.data.searchResultFilterRadius !== null){
        console.log("radius state not null",store.state.data.searchResultFilterRadius);
        radius.value.value = store.state.data.searchResultFilterRadius;
      }
      
    })

    const setLocationFilter = (event,location)=>{
      console.log(location.id);
      if(tagNotInChosenLocations(location)){
        store.dispatch("addSearchFilterStorage",{filter:location})
      }
      else{
        store.dispatch("removeSearchFilterStorage",{filter:location})
      } 
    }

    const tagNotInChosenLocations = (tag) => {
      return props.chosenLocations.findIndex((o)=>{return isEqual(o,tag)}) == -1;
    }

    const handleMapMarkerMoved = (e) => {
      if (e.target && e.target._latlng){
        mapMarker.value = [e.target._latlng.lat,e.target._latlng.lng] // TODO: make Map data all GeoJSON compatible
        calculateUserChoosenArea();
      }
    }

    const handleMapCenterMoved = (e) => {
      console.log("map center moved",e);
      mapCenter.value = e;
    }

    const activateMarker = (e)=>{
      if (mapMarker.value == null){
        let latLngFromMapClickedEvent = e && e.latlng ? [e.latlng.lat,e.latlng.lng] : undefined
        console.log("activating map marker",latLngFromMapClickedEvent,mapCenter.value);
        mapMarker.value = latLngFromMapClickedEvent || mapCenter.value;
        calculateUserChoosenArea();
      }
    }

    const calculateUserChoosenArea = ()=>{
      console.log("radius",radius);
      if (radius.value.value > 50000){
        store.dispatch("triggerToast",{message:"radius for search maximum is 50km"});
        radius.value.value = 50000;
      }
      if (radius.value.value < 1){
        radius.value.value = 1;
      }
      store.commit("setData",{key:"searchResultFilterRadius",value:radius.value.value})
      if (mapMarker.value){
        console.log(mapMarker.value,radius.value.value);
        let center = {               
            "type": "Point",
            "coordinates": [mapMarker.value[1],mapMarker.value[0]]
        };
        let options = {steps: 20, units: 'meters'};
        let newcircle = circle(center,radius.value.value,options)
        console.log(newcircle);
        mapArea.value = newcircle
        updateLocationState();
      }
    }

    const updateLocationState = ()=>{
      console.log("updateLocationState",radius.value.value);
      store.dispatch('addSearchFilterGeolocation',{area:mapArea.value,center:center(mapArea.value),radius:radius.value.value})
    }

      const resetLocationFilters = ()=>{
      mapMarker.value = null;
      //mapArea.value = null;
      radius.value.value = 100;
      store.commit("setData",{key:"searchResultFilterRadius",value:100})
      store.dispatch('removeSearchFilterGeolocation');
    }


    const handleLocationInputUpdate = (e)=>{
      mapMarker.value = [e.center[1],e.center[0]];
      calculateUserChoosenArea();
    }

    return {
      setLocationFilter,
      tagNotInChosenLocations,
      mapMarker,
      mapArea,
      mapCenter,
      handleMapMarkerMoved,
      activateMarker,
      handleMapCenterMoved,
      calculateUserChoosenArea,
      radius,
      resetLocationFilters,
      handleLocationInputUpdate,
    }
    
  }
}
</script>