<template>
  <div class="gallery">
    <!--<img src="@/assets/noimg.png" :alt="'image of ding '+ dingId">-->
    <ImageGallery :imageInFocus="imageInFocus" :imageArray="imageArray" @imageswipe="$emit('imageswipe',$event)" @imagetouchdown="$emit('imagetouchdown',$event)" @imagetouchup="$emit('imagetouchup',$event)"/>
  </div>
</template>

<script>

import {
  //ref
} from 'vue'
import ImageGallery from '@/components/Image/ImageGallery.vue'

export default {
  name: 'DingGallery',
  props: {
    dingId: String,
    imageArray: Array,
    imageInFocus:Number,
  },
  components:{ImageGallery},
  setup(){
    //const imageArray = ref(["./noimg.png","./noimg.png","./noimg.png","./noimg.png","./noimg.png"])
    return{
      // imageArray
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


</style>
