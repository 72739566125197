<template>
  <div>placehold</div>
</template>

<script>
/* 
why is this here?:
 we need this to get the route /SearchItems 
 but we are actually swapping the router-view for SearchItems 
 in App.vue via  dynamic component keep-alive
 to not get errors on the route /SearchItems (which we need to use url params etc.) we need to
 keep this in place:
 see https://forum.vuejs.org/t/vue-router-route-prefix-or-beforeenter-without-component/8426/3
*/
</script>