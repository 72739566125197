<template>
  <div class="gallery">
    
    <img crossorigin="anonymous" :class="{'image':true,'image-in-focus':key===imageInFocus}" v-for="(image,key) in imageArray" :key="key" :src="image+'#' + new Date().getTime()" alt="image in gallery" 
      v-touch:swipe="handleSwipe(key)" v-touch:press="handleTouch(key)" v-touch:release="handleTouchEnded(key)"
    >
    
  </div>
</template>

<script>


export default {
  name: 'ImageGallery',
  props: {
    imageArray: Array,
    imageInFocus: Number,
  },
  setup(props,{emit}){

    const handleSwipe = (key)=>{
      // see https://github.com/robinrodricks/vue3-touch-events#passing-parameters-to-the-event-handler
      return function (direction, mouseEvent) {
        emit('imageswipe',{direction,key,mouseEvent})
      };
    }
    const handleTouch = (key)=>{
      // see https://github.com/robinrodricks/vue3-touch-events#passing-parameters-to-the-event-handler
      return function (mouseEvent) {
        emit('imagetouchdown',{key,mouseEvent})
      };
    }
    const handleTouchEnded = (key)=>{
      return function (mouseEvent) {
        emit('imagetouchup',{key,mouseEvent})
      }
    }
    return {
      handleSwipe,
      handleTouch,
      handleTouchEnded,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.gallery{
  height: 50vh;
  overflow-x:scroll;
  overflow-y:hidden;
  background-color: lightgrey;
  white-space: nowrap;
  
}


</style>
