<template>
  <span :class="['icon', !classOverwrite ? 'has-text-black':'',classOverwrite]">
    <FontAwesomeIcon :icon="icon" />
  </span>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas, fab, far);

export default {
  name: "Icon",
  props: ["icon","classOverwrite"],
  emits: ["onClick"],
  components: { FontAwesomeIcon },
  setup() {
    return {};
  },
};
</script>
