<template>
   <div class="is-full-width m-1 is-flex">
        <Icon class="button is-full-height m-1 has-background-dark has-text-light" :icon="['fas', 'map-marker-alt']" @click="getDeviceLocation"/>
        <Autocomplete
                  id="locationinput"
                  ref="location"
                  debounce=1200
                  max=5
                  use-html-for-results=true
                  input-minlength="4"
                  input-class="input is-rounded"
                  results-container-class="autocomplete-result-container has-background-light"
                  results-item-class="green-on-hover vue3-results-item"
                  placeholder="location by address/city"
                  @input="handleLocationInput"
                  :results="locationSearchResults"
                  @onSelect="handleLocationSelect($event,location)"
                  autocomplete="off"
          ></Autocomplete>
      </div>
</template>
<script>
import Autocomplete from '@/components/Template/Autocomplete.vue'
import { ref } from '@vue/reactivity';
import {onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';


export default {
  name: 'LocationInput',
  components: {
    Autocomplete
  },
  props:["point"],
  emits:["locationUpdate"],
  setup(props, { emit }){
    const store = useStore();

    const location = ref(null);
    const locationSearchResults = ref([]);
    const locationSearchResultsData = ref([]);

    const handleLocationInput = async (e,doNotGeocode=false)=>{
      //console.log("handleLocationInput",e);
      location.value.setText(e);
      if (doNotGeocode) return
      let results = await store.dispatch("forwardGeoCode",e)
      if (!results) return
      locationSearchResultsData.value = results;
      locationSearchResults.value = results.map((i,ind)=>`${i.place_name} <!--${ind}-->`);
      // CONTINUE HERE: 
      /* TODO:
      - style results
      - react to select by using coordinates to trigger/set output
      - remove point prop if not needed
      */
    }

    const handleLocationSelect = (e)=>{
      let text = e.split('<!--')[0];
      let index = e.split('<!--')[1].split('-->')[0]
      console.log("handleLocationsSelect",e,text,index)
      location.value.setText(text);
      let geolocation = locationSearchResultsData.value[index];
      console.log("locationUpdate",geolocation);
      emit("locationUpdate",geolocation);
    }

    const getDeviceLocation = () => {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position)=>{
              //console.log(position);
              handleLocationInput(`${position.coords.longitude},${position.coords.latitude}`,true);
              emit("locationUpdate",{center:[position.coords.longitude,position.coords.latitude]});
          });
      } else {
          alert("please allow geolocation in your browser.")
      }
    }

    onMounted(()=>{
      console.log(props.point);
      //location.value.setText(props.point);
    })

    return {
      location,
      locationSearchResults,
      handleLocationInput,
      handleLocationSelect,
      getDeviceLocation,
    }
  },
}
</script>