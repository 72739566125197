<template>
   <back-button class="ignored-when-printed"/>
   <div :class="{'is-full-height is-overflow-x-scroll':true,'many-per-page':!printOnePerPage}">
     <div class="ignored=when-printed is-fixed is-z-2 is-bottom-0 is-full-width">
      <button class="button ignored-when-printed has-background-pink-3 mb-3" 
          @click="printOnePerPage = !printOnePerPage">
            {{ printOnePerPage ? "ONE PER PAGE" : "MULTIPLE PER PAGE" }}
      </button>
      <button class="button ignored-when-printed has-background-yellow-3 mb-3" 
          @click="handleQRCodeTypeChanged">
            {{ useOnlyIdForQRCode ? "ID ONLY QR CODE" : "URL QR CODE" }}
      </button>
      <button class="button ignored-when-printed has-background-green-3 mb-3" 
          @click="handleIncludeLabelsChanged">
            {{ showNoLabels ? "No Labels" : "Inlcude Labels" }}
      </button>
      <button class="button ignored-when-printed has-background-blue-1 has-text-light mb-3" 
          @click="handlePrintClicked">
            PRINT
      </button>
      
      <button v-if="$store.state.ui.config.cloudprinter" class="button ignored-when-printed has-background-light-1 has-text-dark mb-3" 
          @click="handleCloudPrintClicked">
            CLOUDPRINT
      </button>
     
     </div>
     <h1 class="ignored-when-printed"> 
       QR Codes to print: 
     </h1>
     <div class="qrbox" v-for="(content,key) in qrcodes" :key="key">
       <!-- <div v-if="key !== 0" class="pagebreak-when-printed"> </div> -->
       <QRCode :content="content.id"/>
       <div v-if="!showNoLabels" class="qrcodelabel">{{content.id}}</div>
       <div v-if="!showNoLabels" class="qrcodelabel">{{content.name}}</div>
     </div>
     <div class="ignored-when-printed mb-6"></div>
   </div>
</template>

<script>

import { onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import QRCode from '@/components/Template/QRCode.vue';
import BackButton from '@/components/Template/BackButton.vue';

export default {
  name: "Success",
  emits: [],
  components: { QRCode, BackButton },
  setup() {
    const store = useStore();
    const route = useRoute();
    const useOnlyIdForQRCode = ref(true);
    const showNoLabels = ref(true);
    const printOnePerPage = ref(true);
    const urlPrefix = process.env.VUE_APP_API_URL+"/items/"
    const qrcodes = ref(compileQRCodes());
    
    onMounted(()=>{
      store.dispatch("hideTabBar");
    })
    const handlePrintClicked = ()=>{
      window.print()
    }

    const handleCloudPrintClicked = async ()=>{
      let printerRes = await store.dispatch('cloudPrint',{qrcodes:qrcodes.value});
      if (printerRes?.data?.ok){
        store.dispatch('triggerToast',{message:"success: transmitted to printer"});
      }
    }

    const handleQRCodeTypeChanged = ()=>{
      useOnlyIdForQRCode.value = !useOnlyIdForQRCode.value;
      qrcodes.value = Object.assign([],compileQRCodes());
    }

    const handleIncludeLabelsChanged = () =>{
      showNoLabels.value = !showNoLabels.value;
    }

    function compileQRCodes(){
      if (route.query.singleItem && route.query.singleItem != 'false'){
        console.log('singleItem QR mode',store.state.selected.item.id);
        if (!store.state.selected.item?.id){
          console.error("error using single item qr:",store.state.selected.item?.id);
          store.dispatch('triggerToast',{message:'there is no item selected'})
          return 
        }
        if (useOnlyIdForQRCode.value){
          return [{id:store.state.selected.item?.id, name:store.state.selected.item?.name}]  
        }
        return [{id:urlPrefix+store.state.selected.item?.id, name:store.state.selected.item?.name}]
      }
      console.log('multiItem QR mode');
      if (useOnlyIdForQRCode.value){
          return store.state.data.basket.map((e)=>{return {id:e.id,name:e.name}})
        }
      return store.state.data.basket.map((e)=>{return {id:urlPrefix+e.id,name:e.name}})
    }

    return {
      qrcodes,
      printOnePerPage,
      handlePrintClicked,
      handleCloudPrintClicked,
      useOnlyIdForQRCode,
      handleQRCodeTypeChanged,
      handleIncludeLabelsChanged,
      showNoLabels,
    };
  },
};
</script>

<style scoped>

.success-img{
  height:25vh;
}

</style>
