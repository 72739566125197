/**
 * LAZYLOADER FUNCTION
 * 
 * inspired by example from: https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
 * 
 * adds a intersection Observer that checks if element is in viewport
 * if so: callback is triggered
 * 
 * USAGE:
 * const triggerCallback = () => {
 *       // do stuff when shown in 
 *    
 * }
 * 
 * const rootElement = ref(null);    // reference with ref="root" in template DOM!
 *  onMounted(() => {
 *    addLazyLoadTrigger(root, triggerCallback);  
 * })
 */
export default function addLazyLoadTrigger(root, triggerCallback,msInViewBeforeTrigger=1000) {
    let element=root.value;
    let observed = 0;
  
    function loadComponent() {
      // do sth to activate loading etc.
      triggerCallback();
    }
  
    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          //console.log("INTERSECTING",element,observer);
          //loadComponent();
          observer.unobserve(element);
          observed += 1;
          if (observed > msInViewBeforeTrigger/100){
            return loadComponent();
          }
          setTimeout(()=>{
            createObserver();
          },100)
        }
      });
    }
  
    function createObserver() {
      const observer = new IntersectionObserver(handleIntersect, { root: null, threshold: "1.00" });
      observer.observe(element);
    }
  
    if(window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadComponent();
    }
  }
