<template>
    <div class="qrcode" ref="canvas" :dingsda-qr="content">
    </div>
</template>

<script>

import QRCodeStyling from 'qr-code-styling'
import {  computed, onMounted, ref, watch,  } from '@vue/runtime-core';

export default {
  name: "QRCode",
  props: ["content"],
  emits: [],
  components: { },
  setup(props) {

     const canvas = ref(null);
     let qrCode;
     let qrCodeData = {
              "width":300,
              "height":300,
              "data":props.content,
              "margin":0,
              "qrOptions":{
                "typeNumber":"0",
                "mode":"Byte",
                "errorCorrectionLevel":"Q"
              },
              "imageOptions":{
                "hideBackgroundDots":true,
                "imageSize":0.5,
              },
      }
     const qrCodeOptions =  ref(qrCodeData)

    

    onMounted(()=>{
         updateQRCode()
    })

    watch(computed(()=>props.content),updateQRCode)
     
    function updateQRCode(){
      // clear div from old canvas objects
      let canvasElement = canvas.value.querySelector('canvas');
      if (canvasElement) canvasElement.parentNode.removeChild(canvasElement);
      // append qr canvas
       qrCodeOptions.value = qrCodeData;
       qrCodeOptions.value.data = props.content;
       qrCode = new QRCodeStyling(
           qrCodeOptions.value
        );
        qrCode.append(canvas.value);
    }

    return {canvas};
  },
};
</script>
