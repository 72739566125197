<template>
<div class="backbutton">
  <span class="icon has-text-black">
      <Icon
          class="is-size-4 has-text-dark mr-5"
          :icon="['fa', 'arrow-circle-left']"
          @click="$router.go(-1)"
      />
  </span>
</div>
  
</template>

<script>


export default {
  name: "BackButton",
  emits: ["onClick"],
  components: { },
  setup() {
  },
};
</script>
